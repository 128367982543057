import React, { useState, useEffect } from "react";
import { lazy, Suspense } from "react";

import { IoPricetagsOutline } from "react-icons/io5";
import { RiMailSendLine } from "react-icons/ri";
import Logo_png from "../assets/img/logo.png";
import Logo_webp from "../assets/img/logo.webp";
import { TbPhotoUp } from "react-icons/tb";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Testi_1 from "../assets/img/testi-icon-1.webp";
import Img_Icon_1 from "../assets/img/banner-icon-1.webp";
import Img_Icon_2 from "../assets/img/banner-icon-2.webp";
import Img_Icon_3 from "../assets/img/banner-icon-3.webp";
import Img_Icon_4 from "../assets/img/banner-icon-4.webp";
import Image_banner from "../assets/img/e-commerce/eCommerce-photo-editing-service.webp";
import Feature_New_1 from "../assets/img/landing/landing-icon-2.webp";
import pMinDelay from "p-min-delay";
import Contact_form from "./Contact_form.js";
import { RxLapTimer } from "react-icons/rx";
import { useParams } from "react-router-dom";
import axios from "axios";

const About_setion = lazy(() => pMinDelay(import("./home/About_setion.js"), 5000));
const Feature_section = lazy(() => import("./home/Feature_section.js"));

const Ecommerce_photo_editing_service = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    LoadData();
  }, []);

  function LoadData() {
    const params = {
      slug: slug,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    //

    axios.get("https://api.visualsclipping.com/page-detail?" + queryString).then(
      (response) => {
        setData(response.data.result);
      },
      (error) => {
        if (error.response.status == 401) {
        }
      }
    );
  }

  return (
    <>
      <Helmet>
        <title>{data && data.meta_title}</title>
        <meta name="description" content={data && data.meta_description} />
        <meta name="keyword" content={data && data.meta_keyword} />
        {data && data.slug && <link rel="canonical" href={"https://www.visualsclipping.com/" + data.slug} />}
        <meta name="robots" content="noindex, nofollow, noimageindex, max-snippet:-1, max-video-preview:-1" />
      </Helmet>

      <section className="c-banner-w c-landing-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="c-banner-con">
                <h5>
                  <LazyLoadImage alt={"visuals clipping"} height={24} src={Img_Icon_1} width={24} wrapperProps={{}} />
                  {data && data.header_heading}
                </h5>
                <h1>{data && data.header_sub_heading}</h1>

                <ul>
                  {data &&
                    data.features &&
                    data.features.length > 0 &&
                    data.features.map((item, key) => (
                      <li>
                        <div className="c-banner-feature">
                          {item.image && <img height={36} src={"https://cdn.visualsclipping.com/" + item.image} width={36} />}
                          {item.title}
                        </div>
                      </li>
                    ))}
                </ul>

                <div className="c-banner-btn">
                  <Link to="/contact" className="c-btn-1">
                    <span> Get Quote Now</span>
                  </Link>
                  <button data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
                    <span> Get a Free Trial</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="c-banner-slider">
                <div className="c-banner-slider-list-w">
                  <div className="c-banner-slider-list">{data && data.banner_image && <img alt={data.image_alt} height={36} src={"https://cdn.visualsclipping.com/" + data.banner_image} width={36} />}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-about-w c-featurw-pw landing-about-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="c-testi-in">
                <div className="c-landing-form">
                  <h3> Get Quote Now</h3>
                  <Contact_form />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="c-about-hw">
                <h2>{data && data.why_choose_title}</h2>
                <p>{data && data.why_choose_description}</p>
              </div>
              <div className="cp-fea-list">
                <div className="cp-fea-list-box">
                  <div className="cp-fea-list-box-in">
                    <span>
                      <RiMailSendLine />
                    </span>
                    12 Hours<br></br> Delivery
                  </div>
                  <div className="cp-fea-list-box-in">
                    <span>
                      <RxLapTimer />
                    </span>
                    Time <br></br>Saving
                  </div>
                </div>

                <div className="cp-fea-list-box c-fea-midel">
                  <div className="cp-fea-list-box-in">
                    <picture>
                      <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
                      <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo" />
                      <img src={Logo_png} alt="yazzoo homepage banner" />
                    </picture>
                  </div>
                </div>
                <div className="cp-fea-list-box c-fea-right">
                  <div className="cp-fea-list-box-in">
                    <span>
                      <IoPricetagsOutline />
                    </span>
                    Cost
                    <br></br> Effective
                  </div>
                  <div className="cp-fea-list-box-in">
                    <span>
                      <TbPhotoUp />
                    </span>
                    Professional <br></br> Work
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cn-feature-main cp-service-feature">
        <div className="container">
          <div className="c-feature-w">
            <div className="c-heading-w">
              <h2>{data && data.service_title}</h2>
              <p>{data && data.service_description}</p>
            </div>
            <div className="row">
              {data &&
                data.service &&
                data.service.length > 0 &&
                data.service.map((item, key) => (
                  <div className="col-lg-4 col-md-6">
                    <div className="c-feature-box">
                      <LazyLoadImage alt={"visuals clipping"} height={105} src={Feature_New_1} width={90} wrapperProps={{}} />

                      <h3> {item.title}</h3>
                      <div className="c-landing-box-img">{item.image && <img alt={item.imageAlt} height={36} src={"https://cdn.visualsclipping.com/" + item.image} width={360} />}</div>
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <section className="c-testimonial-w c-landing-teti-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="c-testi-in">
                <div className="c-landing-form">
                  <h3> Get Quote Now</h3>
                  <Contact_form />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="c-testi-list mt-0">
                <div className="c-testi-in mb-5">
                  <div className="c-heading-w">
                    <h5>
                      <img src={Testi_1} alt="visuals clipping " /> Testimonial
                    </h5>
                    <h2>
                      What Our <span>Client</span> Says?
                    </h2>
                    <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                  </div>
                </div>
                <div className="slider-container">
                  <Suspense fallback={<p> Loding </p>}>
                    <About_setion />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Suspense fallback={<p className="c-feature-lodder"> Loding... </p>}>
        <Feature_section />
      </Suspense>
      <section className="c-faq-w landing-faq">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Frequently <span>Asked</span> Questions
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-7 col-md-12 mt-auto mb-auto">
              <div className="c-faq-list">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        How do I know the quality of your eCommerce retouching service before committing?
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We offer a 10-image free trial to showcase our quality and precision before you proceed with a full order.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        What file formats do you support for delivery?
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We support JPEG, PNG, PSD, and TIFF formats based on your preferences.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Do you offer discounts for bulk orders?
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Yes, we offer special pricing for large projects. Contact us for a custom quote.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                        What is the average turnaround time for photo editing?
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We offer a standard 24-hour delivery, with rush delivery available in 3-6 hours based on project needs.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading5">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                        Is there a minimum or maximum number of images I can send?
                      </button>
                    </h2>
                    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>No, we handle projects of all sizes—from single images to large batches—tailoring our service to meet your needs.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading6">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapseThree">
                        How does Visuals Clipping ensure image confidentiality?
                      </button>
                    </h2>
                    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We use secure file transfer systems, and all images are treated with strict confidentiality to protect your brand and content.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading7">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapseThree">
                        What payment options do you offer?
                      </button>
                    </h2>
                    <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We operate on a no-advance-payment basis, letting you assess our service quality before payment is required. We accept PayPal, Wise, and wire transfer.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading7">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapseThree">
                        Can you handle complex products like jewelry?
                      </button>
                    </h2>
                    <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Yes, we specialize in high-detail retouching, providing precision edits for complex items like jewelry</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading7">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapseThree">
                        Are there unlimited revisions?
                      </button>
                    </h2>
                    <div id="collapse9" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Absolutely. We offer unlimited revisions to ensure you’re 100% satisfied with the final images.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="c-faq-img">
                <div className="c-landing-form">
                  <h3> Get Quote Now</h3>
                  <Contact_form />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ecommerce_photo_editing_service;
