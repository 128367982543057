import React from "react";
import Logo_webp from "../assets/img/logo.webp";
import Service_Icon_1 from "../assets/img/srvice-icon-1.webp";
import Service_Icon_2 from "../assets/img/srvice-icon-2.webp";
import Service_Icon_3 from "../assets/img/srvice-icon-3.webp";
import Service_Icon_4 from "../assets/img/srvice-icon-4.webp";
import Service_Icon_5 from "../assets/img/srvice-icon-5.webp";
import Service_Icon_6 from "../assets/img/srvice-icon-6.webp";
import Service_Icon_7 from "../assets/img/srvice-icon-7.webp";
import Service_Icon_8 from "../assets/img/srvice-icon-8.webp";
import Phone_icon from "../assets/img/phone.svg";
import "../assets/css/custom-2.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
const header = () => {
  return (
    // <ul>
    //    <Link to="/">Home</Link>
    //    <Link to="/About">About</Link>
    // </ul>
    <header className="c-header-w">
      <nav className="navbar navbar-expand-lg ">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <LazyLoadImage alt={"visuals clipping logo"} height={43} src={Logo_webp} width={250} wrapperProps={{}} />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".c-poup-off" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse c-nav-in c-poup-off" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link type="button" to="/">
                  {" "}
                  <dfn className="c-moile-nav" data-bs-toggle="collapse" data-bs-target=".c-poup-off">
                    {" "}
                    Home{" "}
                  </dfn>{" "}
                  <dfn className="c-nav-dakstop">Home</dfn>
                </Link>
              </li>
              <li className="nav-item">
                <Link type="button" to="/About">
                  {" "}
                  <dfn className="c-moile-nav" data-bs-toggle="collapse" data-bs-target=".c-poup-off">
                    {" "}
                    About{" "}
                  </dfn>{" "}
                  <dfn className="c-nav-dakstop">About</dfn>
                </Link>
              </li>

              <li className="nav-item dropdown">
                <a className=" dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Services
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <ul>
                    <li>
                      <Link to="/photo-background-removal-service">
                        <span>
                          <picture>
                            <img src={Service_Icon_1} alt="Background Removal" />
                          </picture>
                        </span>
                        <dfn className="c-moile-nav" data-bs-toggle="collapse" data-bs-target=".c-poup-off">
                          {" "}
                          Background Removal{" "}
                        </dfn>{" "}
                        <dfn className="c-nav-dakstop">Background Removal</dfn>
                      </Link>
                    </li>
                    <li>
                      <Link to="/image-clipping-path-service">
                        <span>
                          <picture>
                            <img src={Service_Icon_4} alt="Background Removal" />
                          </picture>
                        </span>
                        <dfn className="c-moile-nav" data-bs-toggle="collapse" data-bs-target=".c-poup-off">
                          Clipping Path{" "}
                        </dfn>{" "}
                        <dfn className="c-nav-dakstop">Clipping Path</dfn>
                      </Link>
                    </li>
                    <li>
                      <Link to="ecommerce-image-editing-service">
                        <span>
                          <picture>
                            <img src={Service_Icon_7} alt="Background Removal" />
                          </picture>
                        </span>
                        <dfn className="c-moile-nav" data-bs-toggle="collapse" data-bs-target=".c-poup-off">
                          {" "}
                          Ecommerce Photo Editing
                        </dfn>{" "}
                        <dfn className="c-nav-dakstop"> Ecommerce Photo Editing</dfn>
                      </Link>
                    </li>
                    <li>
                      <Link to="jewelry-retouching-service">
                        <span>
                          <picture>
                            <img src={Service_Icon_8} alt="Background Removal" />
                          </picture>
                        </span>
                        <dfn className="c-moile-nav" data-bs-toggle="collapse" data-bs-target=".c-poup-off">
                          {" "}
                          Jewellery Photo Editing{" "}
                        </dfn>{" "}
                        <dfn className="c-nav-dakstop"> Jewellery Photo Editing</dfn>
                      </Link>
                    </li>

                    <li>
                      <Link to="/invisible-ghost-mannequin-photo-editing">
                        <span>
                          <picture>
                            <img src={Service_Icon_2} alt="Background Removal" />
                          </picture>
                        </span>
                        <dfn className="c-moile-nav" data-bs-toggle="collapse" data-bs-target=".c-poup-off">
                          {" "}
                          Ghost Mannequin{" "}
                        </dfn>{" "}
                        <dfn className="c-nav-dakstop">Ghost Mannequin</dfn>
                      </Link>
                    </li>
                    <li>
                      <Link to="/photo-editing-service">
                        <span>
                          <picture>
                            <img src={Service_Icon_3} alt="Background Removal" />
                          </picture>
                        </span>
                        <dfn className="c-moile-nav" data-bs-toggle="collapse" data-bs-target=".c-poup-off">
                          {" "}
                          Photo Retouching{" "}
                        </dfn>{" "}
                        <dfn className="c-nav-dakstop">Photo Retouching</dfn>
                      </Link>
                    </li>

                    <li>
                      <Link to="/photo-color-correction-service">
                        <span>
                          <picture>
                            <img src={Service_Icon_5} alt="Background Removal" />
                          </picture>
                        </span>
                        <dfn className="c-moile-nav" data-bs-toggle="collapse" data-bs-target=".c-poup-off">
                          Color Matching{" "}
                        </dfn>{" "}
                        <dfn className="c-nav-dakstop">Color Matching</dfn>
                      </Link>
                    </li>

                    <li>
                      <Link to="automotive-photo-editing">
                        <span>
                          <picture>
                            <img src={Service_Icon_6} alt="Background Removal" />
                          </picture>
                        </span>
                        <dfn className="c-moile-nav" data-bs-toggle="collapse" data-bs-target=".c-poup-off">
                          Automotive Photo Editing
                        </dfn>{" "}
                        <dfn className="c-nav-dakstop">Automotive Photo Editing</dfn>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <Link to="/FAQ">
                  <dfn className="c-moile-nav" data-bs-toggle="collapse" data-bs-target=".c-poup-off">
                    {" "}
                    FAQ{" "}
                  </dfn>{" "}
                  <dfn className="c-nav-dakstop"> FAQ </dfn>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/contact">
                  <dfn className="c-moile-nav" data-bs-toggle="collapse" data-bs-target=".c-poup-off">
                    {" "}
                    Contact{" "}
                  </dfn>{" "}
                  <dfn className="c-nav-dakstop"> Contact </dfn>
                </Link>
              </li>
            </ul>
            <div className="c-call-w">
              <span>
                <img src={Phone_icon} alt="Phone_icon" />
              </span>
              <div className="c-call-in">
                <div className="c-call-in-heading-1">USA/Canada Toll Free</div>
                <div className="c-call-in-heading-2">+ 1(888)535-7543</div>
              </div>
              <a href="mailto:+ 1(888)535-7543"></a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default header;
