import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IoTimeOutline } from "react-icons/io5";
import { FaAnglesRight } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import axios from "axios";
const Blog_Details = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [recent, setRecent] = useState([]);
  useEffect(() => {
    LoadData();
  }, []);

  function LoadData() {
    setLoading(true);
    const params = {
      slug: slug,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    axios.get("https://api.visualsclipping.com/blog-detail?" + queryString).then(
      (response) => {
        setData(response.data.result);
        setCategories(response.data.categories);
        setRecent(response.data.recent);
      },
      (error) => {
        if (error.response.status == 401) {
        }
      }
    );
  }
  function formatISODate(isoDate) {
    const date = new Date(isoDate);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  return (
    <>
      <Helmet>
        <title>{data && data.seo_title}</title>
        {data && <meta name="description" content={data && data.seo_description} />}

        {data && <meta itemprop="datePublished" content={data.createdAt} />}
        {data && <meta itemprop="dateModified" content={data.updatedAt} />}

        {data && data.slug && <link rel="canonical" href={"https://www.visualsclipping.com/blog/" + data.slug} />}
      </Helmet>

      <section class="c-inner-page-w mt-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
              <div class="c-singale-blog">
                <div class="cp-blog-list-box">
                  <div class="cp-blog-list-box-img">
                    {data && data.images && data.images.length > 0 && (
                      <LazyLoadImage
                        alt={data && data.name}
                        height={586}
                        src={"https://cdn.visualsclipping.com/" + data.images[0].replace("/uploads/", "")}
                        width={1043}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    )}
                  </div>
                  <div class="cp-blog-list-box-con">
                    <div class="c-blog-date">
                      <span>
                        <IoTimeOutline />
                      </span>
                      <h4>{data && formatISODate(data.createdAt)}</h4>
                    </div>

                    <h3>
                      <a href="#">{data && data.name}</a>
                    </h3>
                    <div>{data && <div dangerouslySetInnerHTML={{ __html: data.description }} />}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-12">
              <div class="c-blog-sidebar-w">
                <div class="c-blog-category-w ">
                  <div class="wp-block-group__inner-container">
                    <div class="wp-block-heading c-recent-news-heading-1">Category</div>

                    <ul>
                      {categories &&
                        categories.length > 0 &&
                        categories.map((item, key) => (
                          <li class="cat-item ">
                            <a href={"/blog?category=" + item._id}>
                              <FaAnglesRight /> {item.name}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div class="c-recent-news-w">
                <div class="c-recent-news-heading-1">Recent News</div>
                  <ul class="c-latest-post">
                    {recent &&
                      recent.length > 0 &&
                      recent.map((item, key) => (
                        <li class="cat-item ">
                          <a href={"/blog/" + item.slug}>
                            <li>
                              <div class="c-recent-news-img ">
                                {item.images && item.images.length > 0 && (
                                  <LazyLoadImage
                                    alt={"Background Removal Services"}
                                    height={586}
                                    src={"https://cdn.visualsclipping.com/" + item.images[0].replace("/uploads/", "")}
                                    width={1043}
                                    effect="blur"
                                    wrapperProps={{
                                      style: { transitionDelay: "1s" },
                                    }}
                                  />
                                )}
                              </div>
                              <div class="c-recent-news-con">
                                <a href={"/blog/" + item.slug}>
                                  <div className="c-recent-news-con-heading">{item.name}</div>
                                </a>
                              </div>
                            </li>
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog_Details;
