import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import axios from "axios";
const Blog_Details = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    LoadData();
  }, []);

  function LoadData() {
    setLoading(true);
    const params = {
      slug: slug,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    axios.get("https://api.visualsclipping.com/job-detail?" + queryString).then(
      (response) => {
        setData(response.data.result);
      },
      (error) => {
        if (error.response.status == 401) {
        }
      }
    );
  }

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    resume: null,
  });

  const allowedFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/plain", "application/rtf"];

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, resume: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, message, resume } = formData;

    // Validate fields
    if (!name || !email || !resume) {
      alert("Please fill out all required fields.");
      return;
    }

    // Validate file type
    if (!allowedFileTypes.includes(resume.type)) {
      alert("Invalid file type. Please upload a .doc, .docx, .pdf, .txt, or .rtf file.");
      return;
    }

    // Prepare form data
    const submissionData = new FormData();
    submissionData.append("name", name);
    submissionData.append("email", email);
    submissionData.append("message", message);
    submissionData.append("resume", resume);
    submissionData.append("job", data._id);
    try {
      const response = await fetch("https://api.visualsclipping.com/submit-application", {
        method: "POST",
        body: submissionData,
      });
      const data = await response.json();
      if (data.success) {
        window.location.replace("/thank-you-job-submit");
        // Reset the form
        setFormData({ name: "", email: "", message: "", resume: null });
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message || "Something went wrong!"}`);
      }
    } catch (error) {
      alert("Error submitting application: " + error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>{data && data.seo_title}</title>
        <meta name="description" content={data && data.seo_description} />

        {data && <meta itemprop="datePublished" content={data.createdAt} />}
        {data && <meta itemprop="dateModified" content={data.updatedAt} />}
      </Helmet>

      <section class="c-inner-page-w mt-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
              <div class="c-singale-blog">
                <div class="cp-blog-list-box">
                  <div class="cp-blog-list-box-img">
                    {data && data.images && data.images.length > 0 && (
                      <LazyLoadImage
                        alt={data && data.name}
                        height={586}
                        src={"https://cdn.visualsclipping.com/" + data.images[0].replace("/uploads/", "")}
                        width={1043}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    )}
                  </div>
                  <div class="cp-blog-list-box-con">
                    <h3>
                      <a href="#">{data && data.name}</a>
                    </h3>
                    <div>{data && <div dangerouslySetInnerHTML={{ __html: data.description }} />}</div>

                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#applyModal">
                      Apply Now
                    </button>
                  </div>

                  <div className="modal fade" id="applyModal" tabIndex="-1" aria-labelledby="applyModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="applyModalLabel">
                            Application Form
                          </h1>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <label htmlFor="name" className="form-label">
                                Name
                              </label>
                              <input type="text" className="form-control" id="name" value={formData.name} onChange={handleChange} required />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="email" className="form-label">
                                Email address
                              </label>
                              <input type="email" className="form-control" id="email" value={formData.email} onChange={handleChange} required />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="resume" className="form-label">
                                Resume
                              </label>
                              <input type="file" className="form-control" id="resume" onChange={handleFileChange} required />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="message" className="form-label">
                                Message
                              </label>
                              <textarea className="form-control" id="message" rows="3" value={formData.message} onChange={handleChange}></textarea>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                Close
                              </button>
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog_Details;
