import React from "react";
import { lazy, Suspense } from "react";

import { IoPricetagsOutline } from "react-icons/io5";
import { RiMailSendLine } from "react-icons/ri";
import Logo_png from "../assets/img/logo.png";
import Logo_webp from "../assets/img/logo.webp";
import { TbPhotoUp } from "react-icons/tb";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Testi_1 from "../assets/img/testi-icon-1.webp";
import Img_Icon_1 from "../assets/img/banner-icon-1.webp";
import Img_Icon_2 from "../assets/img/banner-icon-2.webp";
import Img_Icon_3 from "../assets/img/banner-icon-3.webp";
import Img_Icon_4 from "../assets/img/banner-icon-4.webp";
import Image_banner from "../assets/img/landing/image-clipping-path-services.webp";
import Feature_New_1 from "../assets/img/landing/landing-icon-2.webp";
import pMinDelay from "p-min-delay";
import Landing_img_1 from "../assets/img/landing/hand-made-clipping-path.webp";
import Landing_img_2 from "../assets/img/landing/medium-product-clipping-path-b.webp";
import Landing_img_3 from "../assets/img/landing/complex-product-clipping-path-service-b.webp";
import Contact_form from "./Contact_form.js";

import { RxLapTimer } from "react-icons/rx";
import Landing_img_5 from "../assets/img/landing/image-masking-service-b.webp";
import Landing_img_6 from "../assets/img/landing/sunglass-clipping-path-b.webp";

const About_setion = lazy(() => pMinDelay(import("./home/About_setion.js"), 5000));

const Feature_section = lazy(() => import("./home/Feature_section.js"));

const landing = () => {
  return (
    <>
      <Helmet>
        <title>Professional Image Clipping Path Service | Precise & Affordable Editing</title>
        <meta
          name="description"
          content="Transform your images with Visuals Clipping’s expert image clipping path services. We provide hand-drawn paths, precision edits, and quick turnaround times. Enjoy high-quality, cost-effective results with a free trial and no upfront payment required. Perfect for eCommerce!"
        />
      </Helmet>

      <section className="c-banner-w c-landing-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="c-banner-con">
                <h5>
                  <LazyLoadImage alt={"visuals clipping"} height={24} src={Img_Icon_1} width={24} wrapperProps={{}} />
                  Clipping Path
                </h5>
                <h1>
                  Expert <span>Clipping Path </span> Services!
                </h1>

                <ul>
                  <li>
                    <div className="c-banner-feature">
                      <LazyLoadImage alt={"visuals clipping"} height={36} src={Img_Icon_1} width={36} wrapperProps={{}} />
                      100% Satisfaction
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <LazyLoadImage alt={"visuals clipping"} height={36} src={Img_Icon_2} width={36} wrapperProps={{}} />
                      No Advanced Payment
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <LazyLoadImage alt={"visuals clipping"} height={36} src={Img_Icon_3} width={36} wrapperProps={{}} />
                      Unlimited Revision
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <LazyLoadImage alt={"visuals clipping"} height={36} src={Img_Icon_4} width={36} wrapperProps={{}} />
                      10 Image Free Trail
                    </div>
                  </li>
                </ul>

                <div className="c-banner-btn">
                  <Link to="/contact" className="c-btn-1">
                    <span> Get Quote Now</span>
                  </Link>
                  <button data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
                    <span> Get a Free Trial</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="c-banner-slider">
                <div className="c-banner-slider-list-w">
                  <div className="c-banner-slider-list">
                    <LazyLoadImage alt={"Image Clipping Path Services"} height={592} src={Image_banner} width={678} wrapperProps={{}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-about-w c-featurw-pw landing-about-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="c-testi-in">
                <div className="c-landing-form">
                  <h3> Get Quote Now</h3>
                  <Contact_form />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="c-about-hw">
                <h2>
                  Benefits to outsource Photo Retouching <br></br> Services to <span>Clipping Path</span>{" "}
                </h2>
                <p>ou are not restricted to choices when you choose Visuals Clipping. Our clients receive personalized services as per their needs.</p>
              </div>
              <div className="cp-fea-list">
                <div className="cp-fea-list-box">
                  <div className="cp-fea-list-box-in">
                    <span>
                      <RiMailSendLine />
                    </span>
                    12 Hours<br></br> Delivery
                  </div>
                  <div className="cp-fea-list-box-in">
                    <span>
                      <RxLapTimer />
                    </span>
                    Time <br></br>Saving
                  </div>
                </div>

                <div className="cp-fea-list-box c-fea-midel">
                  <div className="cp-fea-list-box-in">
                    <picture>
                      <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
                      <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo" />
                      <img src={Logo_png} alt="yazzoo homepage banner" />
                    </picture>
                  </div>
                </div>
                <div className="cp-fea-list-box c-fea-right">
                  <div className="cp-fea-list-box-in">
                    <span>
                      <IoPricetagsOutline />
                    </span>
                    Cost
                    <br></br> Effective
                  </div>
                  <div className="cp-fea-list-box-in">
                    <span>
                      <TbPhotoUp />
                    </span>
                    Professional <br></br> Work
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cn-feature-main cp-service-feature">
        <div className="container">
          <div className="c-feature-w">
            <div className="c-heading-w">
              <h2>
                What we offer in <span>Clipping Path Services?</span>{" "}
              </h2>
              <p>Visuals Clipping offers a variety of high-quality clipping path services to meet your exact image editing requirements. </p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage alt={"visuals clipping"} height={105} src={Feature_New_1} width={90} wrapperProps={{}} />

                  <h3>Hand-made High-Quality Clipping Path Service</h3>
                  <div className="c-landing-box-img">
                    <LazyLoadImage alt={"Hand Made Clipping Path Service"} height={287} src={Landing_img_1} width={360} wrapperProps={{}} />
                  </div>
                  <p>
                    We specialize in creating manual, hand-drawn clipping paths for precision and detail, ensuring the highest quality image cut-outs. Whether it’s product images for e-commerce or creative visuals, we prioritize accuracy for a clean, polished result. This method eliminates unwanted
                    backgrounds, enhances clarity, and ensures your product images stand out
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage alt={"visuals clipping"} height={105} src={Feature_New_1} width={90} wrapperProps={{}} />

                  <h3>Medium Complexity Photo Clipping Path</h3>
                  <div className="c-landing-box-img">
                    <LazyLoadImage alt={"Product Clipping path Service"} height={287} src={Landing_img_2} width={360} wrapperProps={{}} />
                  </div>
                  <p>
                    For images with medium detail, like clothing, shoes, or accessories, our clipping path services provide precise cut-outs while maintaining a natural look. Our editors expertly handle moderately complex shapes and curves to ensure your images look seamless and professional. We
                    focus on quick turnarounds without compromising quality, perfect for e-commerce stores with bulk needs.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage alt={"visuals clipping"} height={105} src={Feature_New_1} width={90} wrapperProps={{}} />

                  <h3>Clipping Path for Complex Images</h3>
                  <div className="c-landing-box-img">
                    <LazyLoadImage alt={"Complex Image Clipping Path"} height={287} src={Landing_img_3} width={360} wrapperProps={{}} />
                  </div>
                  <p>
                    hen it comes to complex objects like jewelry, electronics, or machinery, we excel at creating detailed clipping paths that capture every intricate element. Our team uses advanced techniques to ensure high precision and sharpness, even in the most challenging images. This service
                    ensures your products are perfectly highlighted, enhancing visual appeal and trust with customers.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage alt={"visuals clipping"} height={105} src={Feature_New_1} width={90} wrapperProps={{}} />

                  <h3>Multiple Clipping Paths for Layered Images</h3>
                  <div className="c-landing-box-img">
                    <LazyLoadImage alt={"Multiple Clipping Path Service"} height={287} src={Landing_img_3} width={360} wrapperProps={{}} />
                  </div>
                  <p>
                    For products or images requiring multiple layers and sections, our experts create multiple clipping paths, giving you control over each individual part. Whether you need to adjust colors, shadows, or textures separately, our service allows for ultimate flexibility and
                    post-editing precision. This is ideal for catalogues or any images with various elements that require independent editing.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage alt={"visuals clipping"} height={105} src={Feature_New_1} width={90} wrapperProps={{}} />

                  <h3>Image Masking & Channel Masking</h3>
                  <div className="c-landing-box-img">
                    <LazyLoadImage alt={"Image Masking Service"} height={287} src={Landing_img_5} width={360} wrapperProps={{}} />
                  </div>
                  <p>
                    For images with fine details like hair, fur, or transparent objects, image masking is the perfect solution. We use both basic and channel masking techniques to isolate complex areas without losing detail. This process works well for high-end fashion, cosmetics, or any image
                    requiring detailed edge refinements. Our masking ensures perfect, professional-grade results every time.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage alt={"visuals clipping"} height={105} src={Feature_New_1} width={90} wrapperProps={{}} />

                  <h3>Background Removal for Flawless Images</h3>
                  <div className="c-landing-box-img">
                    <LazyLoadImage alt={"Image Clipping Path Service"} height={287} src={Landing_img_6} width={360} wrapperProps={{}} />
                  </div>
                  <p>
                    Our Background Removal Service is designed to isolate your product or subject with meticulous accuracy, creating a clean, distraction-free image. Whether you're enhancing e-commerce listings or preparing images for professional catalogs, our team ensures perfect cut-outs that
                    highlight your products while maintaining natural edges. We handle everything from simple objects to more complex subjects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-testimonial-w c-landing-teti-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="c-testi-in">
                <div className="c-landing-form">
                  <h3> Get Quote Now</h3>
                  <Contact_form />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="c-testi-list mt-0">
                <div className="c-testi-in mb-5">
                  <div className="c-heading-w">
                    <h5>
                      <img src={Testi_1} alt="visuals clipping " /> Testimonial
                    </h5>
                    <h2>
                      What Our <span>Client</span> Says?
                    </h2>
                    <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                  </div>
                </div>
                <div className="slider-container">
                  <Suspense fallback={<p> Loding </p>}>
                    <About_setion />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Suspense fallback={<p className="c-feature-lodder"> Loding... </p>}>
        <Feature_section />
      </Suspense>
      <section className="c-faq-w landing-faq">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Frequently <span>Asked</span> Questions
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-7 col-md-12 mt-auto mb-auto">
              <div className="c-faq-list">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        How do I know the quality of your clipping path service before committing?
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We offer a 10-image free trial to showcase our quality and precision before you proceed with a full order.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        What file formats do you support for delivery?
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We support JPEG, PNG, PSD, and TIFF formats based on your preferences.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What is your typical turnaround time?
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Our turnaround time ranges from 3 to 24 hours depending on the complexity and volume of the work.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                        Are revisions included if I’m not satisfied?
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Yes, unlimited revisions are included to ensure you are 100% satisfied.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading5">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                        Do you offer discounts for bulk orders?
                      </button>
                    </h2>
                    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Yes, we offer special pricing for large projects. Contact us for a custom quote</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading6">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapseThree">
                        How do you protect the confidentiality of my images?
                      </button>
                    </h2>
                    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We prioritize security and confidentiality with strict measures to protect all client files.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading7">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapseThree">
                        Can you handle complex image editing needs?
                      </button>
                    </h2>
                    <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Yes, we specialize in all levels of complexity, from simple edits to intricate products like jewelry.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="c-faq-img">
                <div className="c-landing-form">
                  <h3> Get Quote Now</h3>
                  <Contact_form />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default landing;
