import "./App.css";
import "./assets/css/custom-2.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ReactGA from "react-ga4";
import "react-lazy-load-image-component/src/effects/blur.css";
import Headers from "./components/header";
import Footer from "./components/footer";
import About from "./components/About";
import FAQ from "./components/FAQ";
import Home from "./components/home";
import Contact from "./components/Contact";
import Background_Removal from "./components/Background_Removal";
import Ghost from "./components/Ghost_Mannequin";
import Photo_Retouching from "./components/Photo_Retouching";
import Clipping_Path from "./components/Clipping_Path";
import Color_matching from "./components/Color_Matching";
import Automotive_Photo_Editing from "./components/Automotive_Photo_Editing";
import Ecommerce_photo_editing from "./components/Ecommerce_Photo_Editing";
import Jewellery_Photo_Editing from "./components/Jewellery_Photo_Editing";
import Thank_you from "./components/Thank_you.js";
import Privacy from "./components/Privacy.js";
import Terms from "./components/Terms.js";
import Blog from "./components/Blog.js";
import Jobs from "./components/Jobs.js";

import Landing from "./components/landing.js";
import Blog_Details from "./components/Blog_Details.js";
import Background_Removal_Services from "./components/Background_Removal_Services.js";
import Ecommerce_photo_editing_service from "./components/ecommerce_photo_editing_service.js";
import Jewelry_photo_editing from "./components/Jewelry_photo_editing.js";
import Thank_you_landing from "./components/Thank_you_landing.js";
import Dynamic_Landing from "./components/Dynamic_Landing.js";
import Job_Details from "./components/Job_Details.js";
import Thank_you_jobsubmit from "./components/Thank_you_jobsubmit.js";

function App() {
  const TRACKING_ID = "AW-976233181";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: "pageview", page: "/" });
  return (
    <>
      <Router>
        <Headers></Headers>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/FAQ">
            <FAQ />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/photo-background-removal-service">
            <Background_Removal />
          </Route>
          <Route exact path="/invisible-ghost-mannequin-photo-editing">
            <Ghost />
          </Route>

          <Route exact path="/photo-editing-service">
            <Photo_Retouching />
          </Route>

          <Route exact path="/image-clipping-path-service">
            <Clipping_Path />
          </Route>

          <Route exact path="/photo-color-correction-service">
            <Color_matching />
          </Route>

          <Route exact path="/automotive-photo-editing">
            <Automotive_Photo_Editing />
          </Route>

          <Route exact path="/ecommerce-image-editing-service">
            <Ecommerce_photo_editing />
          </Route>
          <Route exact path="/jewelry-retouching-service">
            <Jewellery_Photo_Editing />
          </Route>
          <Route exact path="/privacy-policy">
            <Privacy />
          </Route>
          <Route exact path="/terms-and-conditions">
            <Terms />
          </Route>

          <Route exact path="/blog">
            <Blog />
          </Route>

          <Route exact path="/blog/:slug">
            <Blog_Details />
          </Route>

          <Route exact path="/jobs">
            <Jobs />
          </Route>
          <Route exact path="/jobs/:slug">
            <Job_Details />
          </Route>

          <Route exact path="/image-clipping-service">
            <Landing />
          </Route>

          <Route exact path="/thank-you">
            <Thank_you />
          </Route>

          <Route exact path="/Thank-you-for-getting-in-touch">
            <Thank_you_landing />
          </Route>

          <Route exact path="/background-removal-services">
            <Background_Removal_Services />
          </Route>

          <Route exact path="/ecommerce-photo-editing-service">
            <Ecommerce_photo_editing_service />
          </Route>

          <Route exact path="/jewelry-photo-editing">
            <Jewelry_photo_editing />
          </Route>

          <Route exact path="/thank-you-job-submit">
            <Thank_you_jobsubmit />
          </Route>

          <Route exact path="/:slug">
            <Dynamic_Landing />
          </Route>
        </Switch>
        <Footer></Footer>
      </Router>
    </>
  );
}

export default App;
